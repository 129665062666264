<template>
  <b-row>
    <b-col
      cols="12"
      md="12"
    >
      <b-card title="MailX ">
        <b-card-text>-</b-card-text>
        <MailXOrderList />
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import MailXOrderList from '@/components/mailx/MailXOrderList.vue'
import {
  BRow, BCol, BCard, BCardText,
} from 'bootstrap-vue'

export default {
  components: {
    BRow, BCol, BCard, BCardText, MailXOrderList,
  },
}
</script>

<style>

</style>
