<template>
  <div>

    <b-table
      small
      :fields="fields"
      :items="orders"
      responsive="sm"
    >

      <template #cell(iat)="data">
        {{ convertTimestamp(data.value) }}
      </template>

      <template #cell(exp)="data">
        {{ convertTimestamp(data.value) }}
      </template>

      <template #cell(status)="data">
        <b-badge
          pill
          :variant="getStatusVariant(data.value)"
        >
          {{ fixStatus(data.value) }}
        </b-badge>
      </template>

      <!-- Optional default data cell scoped slot -->
      <template #cell(action)="data">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="info"
          @click="viewOrder(data.item.ID)"
        >
          管理
        </b-button>
      </template>

    </b-table>

    <div class="block">
      <b-pagination
        v-model="arg.page"
        :per-page="arg.pageSize"
        hide-goto-end-buttons
        :total-rows="total"
        @click.native="onPageChange"
      />
    </div>
  </div>
</template>

<script>
import { bytesToSize } from '@/utils/flow'
import { convertTimestamp } from '@/utils/format-date'
import { notify, throwError } from '@/utils/utils'
import Ripple from 'vue-ripple-directive'
import useHttp from '@/http/useHttp'
import {
  BButton, BTable, BBadge, BPagination,
} from 'bootstrap-vue'

export default {
  components: {
    BButton,
    BTable,
    BBadge,
    BPagination,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: [
        { key: 'ID', label: 'ID', sortable: true },
        { key: 'email', label: 'Email' },
        { key: 'Status', label: 'Status' },
        { key: 'iat', label: '创建', sortable: true },
        { key: 'exp', label: '过期', sortable: true },
        'action',
      ],
      orders: [],
      total: 5,
      arg: {
        pageSize: 10,
        page: 1,
      },
      error: {},
    }
  },
  created() {
    this.refreshData(this.arg)
  },
  methods: {
    getStatusVariant(s) {
      if (s === 0) {
        return 'info'
      }
      return 'success'
    },
    fixStatus(s) {
      if (s === 3) {
        return '失效'
      }
      return '可用'
    },
    handleSizeChange(size) {
      this.arg.pageSize = size
      this.refreshData()
    },
    handleCurrentChange(currentPage) {
      this.arg.page = currentPage
      this.refreshData()
    },
    onPageChange() {
      this.refreshData(this.arg)
    },
    viewOrder(id) {
      this.$router.push({ name: 'mailx-order-info', params: { id } })
    },
    refreshData(arg) {
      useHttp.getMailXOrders({
        params: arg,
      }).then(res => {
        this.total = res.data.data.count
        this.orders = res.data.data.data
        // console.log(this.total)
      }).catch(error => {
        throwError(error)
        notify('Error', '获取失败', 'danger')
      })
    },
    bytesToSize,
    convertTimestamp,
  },
}
</script>
